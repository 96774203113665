import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Label } from "semantic-ui-react";
import { useAtom } from "jotai";
import { countryAtom, currencyAtom, currenciesAtom, showVatAtom } from "../state.js";
import { userAtom } from "../atoms/authAtom.js";
import usePrice from "../hooks/usePrice.js";
import { formattedPrice } from "../utils/price";

const PriceLabel = ({ product, color, size = "large" }) => {
  const [country] = useAtom(countryAtom);
  const [currency] = useAtom(currencyAtom);
  const [currencies] = useAtom(currenciesAtom);
  const [showVat] = useAtom(showVatAtom);
  const [user] = useAtom(userAtom);

  const { calculatedPrice } = usePrice();

  const [price, setPrice] = useState(null);

  useEffect(() => {
    let price = calculatedPrice(product);

    if (product.discontinued && product.stock === 0) {
      setPrice("Discontinued");
    } else if (product.prices && product.prices[currency]) {
      setPrice(formattedPrice(price, currencies[currency].symbol, currencies[currency].right_side));
    } else {
      setPrice("?");
    }
  }, [product?.prices, product?.stock, country, currency, currencies, showVat, user]);

  return (
    <>
      <meta itemProp="priceCurrency" content={currencies[currency]?.iso4217} />
      <meta itemProp="price" content={calculatedPrice(product)} />
      <Label color={color} size={size}>
        {price}
      </Label>
    </>
  );
};

export default React.memo(PriceLabel);
